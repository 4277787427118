export const ACTION_TYPES = {
  START: "START",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  LOGOUT: "LOGOUT",
};
export const DEFAULT_NAMES = {
  APP_NAME: "CareOS",
};
export const USER_ROLES = {
  DEMOUSER: "demouser",
  ADMINISTRATOR: "administrator",
  CLIENT: "client",
};
