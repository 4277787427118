import "./landingtopbar.css";
import { useNavigate } from "react-router-dom";

const TopBar = ({ toggleSideBar }) => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    console.log('Logging in');
    navigate("/login");
  };

  return (
    <header id="landingPageHeader">
      <div className="landingTopBarWrapper" onClick={toggleSideBar}>
        <div className="navWrapper">
          <div className="landingTopBarCompanyLogo">
            <span className="companyLogo">CareOS</span>
          </div>
          <ul className="landingTopBarMenu">
            <li className="menuItem"><a href="#landingHome" className="landingTopBarLink">Home</a></li>
            <li className="menuItem"><a href="#landingAbout" className="landingTopBarLink">About</a></li>
            <li className="menuItem"><a href="#landingContact" className="landingTopBarLink">Contact</a></li>
          </ul>
        </div>
        <div className="landingTopBarButtons">
          <button className="btnHeader btnLogin" onClick={handleLoginClick}>Login</button>
          <button className="btnHeader btnGetStarted">Get Started</button>
        </div>
      </div>
    </header>
  );
};

export default TopBar;