import "./hometopbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const TopBar = () => {
  // const history = useHistory();
  const navigate = useNavigate();

  const handleLogout = e => {
    e.preventDefault();
    console.log('login')
    // Redirect to login page
    navigate("/login");
  };

  return (
    <header id="appHeader">
      {/* <div className="landingTopBarMenuToggle" onClick={toggleSideBar}>
                <FontAwesomeIcon icon={faBars} className="landingTopBarMenuToggleIcon" onClick={toggleSideBar} />
                <span onClick={toggleSideBar}>MENU</span>
            </div> */}
      <div className="companyLogo">
        CareOS
      </div>
      <Link to={handleLogout} className="landingTopBarLink">
        <div className="landingTopBarLogout" >
          demouser
          {/* <FontAwesomeIcon icon={faRightFromBracket} onClick={handleLogout}/> */}
        </div>
      </Link>
    </header >
  );
};

export default TopBar;
