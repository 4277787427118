import "./sidebar.css";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import SideBarItem from "../SideBarItem/SideBarItem";

const SideBar = ({ data }) => {
  // const { user } = useContext(AuthContext);
  // const { permissions } = user;
  // const sidebarByAccess = data.filter((item) =>
  //   item.access.includes(permissions[0])
  // );

  return (
    <div className={`sideBar`}>
      <div className="sideBarContainer">
        <div className="sideBarContents">
          {Object.keys(data).length === 0 ? (
            "No side bar items retrieved"
          ) : (
            <>
              {data.map((item) => (
                <SideBarItem
                  key={item.id}
                  url={item.path}
                  icon={item.icon}
                  label={item.title}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
