import "./demo.css";
import { Routes, Route } from "react-router-dom";

import HomeTopBar from "../../components/TopBar/HomeTopBar/HomeTopBar";
import SideBar from "../../components/SideBar/SideBar";
import HomeFeed from "../../components/HomeFeed/HomeFeed";

const Demo = () => {
  const data = [
    {
      title: "Home",
      path: "./home",
      icon: "faGlobe",
      id: "1"
    },
    {
      title: "Users",
      path: "./users",
      icon: "faUser",
      id: "2"
    },
    {
      title: "Teams",
      path: "./teams",
      icon: "faPeopleGroup",
      id: "3"
    },
    {
      title: "Billing",
      path: "./billing",
      icon: "faUserCheck",
      id: "4"
    },
    {
      title: "Settings",
      path: "./settings",
      icon: "faWrench",
      id: "5"
    },
  ]
  return (
    <div className="demoContainer">
      <HomeTopBar />
      <SideBar data={data} />
      <Routes>
        {data.map((item) => (       
          <Route
            key={item.path}
            path={item.path.replace('.', '')}
            element={<HomeFeed section={item?.title} />}
          />
        ))}
        <Route path="/" element={<HomeFeed section={data[0]?.title} />} />
      </Routes>
    </div>
  )
}

export default Demo