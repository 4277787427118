import "./login.css";
import React, { useState, useRef, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { ACTION_TYPES } from "../../utils/definitions.js";
import requestUrl from "../../utils/requestMethods.js";

const Login = () => {
  const navigate = useNavigate();
  // const { loading, error, dispatch } = useContext(AuthContext);
  const passwordRef = useRef();
  const [credentials, setCredentials] = useState({
    domainId: undefined,
    password: undefined,
  });
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const handleChange = (e) => {
    setError(false)
    setErrorMsg('')
    let input = e.target.value;
    if (e.target.id === "username") input = input.toUpperCase();
    setCredentials((prev) => ({ ...prev, [e.target.id]: input }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (credentials.username !== 'demouser' && credentials.password !== 'test') {
      setError(true)
      setErrorMsg('Invalid credentials')
      return
    }
    navigate('/demo')
  };

  const togglePassword = (e) => {
    e.preventDefault();
    passwordRef.current.type = isPasswordHidden ? "text" : "password";
    setIsPasswordHidden(!isPasswordHidden);
  };

  return (
    <div className="login">
      <div className="loginTitles">
        <div className="logoLogin">CareOS</div>
        <div className="companyDescription">The Operating System for Customer Care Operations</div>
      </div>
      <div className="loginContainer">

        <input
          type="text"
          placeholder="Username"
          id="username"
          className={`loginInput domainId`}
          onChange={handleChange}
          maxLength="8"
        />
        <div className="loginPasswordWrap">
          <input
            type="password"
            placeholder="Password"
            id="password"
            className={`loginInput password`}
            onChange={handleChange}
            ref={passwordRef}
          />
          <FontAwesomeIcon
            icon={isPasswordHidden === true ? faEyeSlash : faEye}
            className="loginEye"
            onClick={togglePassword}
          />
        </div>
        <button className="loginButton" onClick={handleLogin}>
          Login
        </button>
        {error && <span className="errorMessage"> {errorMsg} </span>}
      </div>
    </div>
  );
};
export default Login;
