import axios from "axios";

const serverMode = true;
const localhost = "http://localhost:8800/api";
const production = "https://careos.net/api";

export const requestUrl = axios.create({
  baseURL: serverMode ? production : localhost,
  headers: {
    "Content-Type": "application/json",
  },
});

// export const serviceUrl = serverMode ? production : localhost

export default requestUrl;
