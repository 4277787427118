import "./sideBarItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faGlobe,
  faPeopleGroup,
  faWrench,
  faUser,
  faGear,
  faUserCheck,
  faTicket
} from "@fortawesome/free-solid-svg-icons";

const SideBarItem = ({ url, icon, label }) => {
  let derivedIcon;
  switch (icon) {
    case "faGlobe":
      derivedIcon = faGlobe;
      break;
    case "faWrench":
      derivedIcon = faWrench;
      break;
    case "faPeopleGroup":
      derivedIcon = faPeopleGroup;
      break;
    case "faUser":
      derivedIcon = faUser;
      break;
    case "faGear":
      derivedIcon = faGear;
      break;
    case "faUserCheck":
      derivedIcon = faUserCheck;
      break;
    case "faTicket":
      derivedIcon = faTicket;
    default:
      break;
  }
  return (
    <Link className="sideBarLink" to={url}>
      <div className="sideBarItem">
        <div className="sideBarItemIcon">
          <FontAwesomeIcon icon={derivedIcon} />
        </div>
        <label>{label}</label>
      </div>
    </Link>
  );
};

export default SideBarItem;
