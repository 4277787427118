import React from "react";
import "./homeFeed.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faGoogle,
  faInstagram,
  faPlus,
  faHandshakeAngle,
  faClock,
  faTimeline,
  faScrewdriverWrench,
  faComments,
  faPeopleCarryBox,
  faGraduationCap,
  faUserCheck,
  faChalkboardUser,
} from "@fortawesome/free-solid-svg-icons";

const AppCard = ({ icon, title }) => {
  return (
    <div className='appCard'>
      <div className='cardImage'>
        <FontAwesomeIcon icon={icon} className='appItemIcon' />
      </div>
      <div className='cardTitle'>
        <label>{title}</label>
      </div>
    </div>
  );
};

const HomeFeed = ({ section }) => {
  const iconsAndLabels = [
    { icon: faChalkboardUser, title: "Demand Planning" },
    { icon: faUserCheck, title: "Hiring Delivery" },
    { icon: faGraduationCap, title: "Learning Delivery" },
    { icon: faPeopleCarryBox, title: "Agent Assist" },
    { icon: faComments, title: "Group Chat" },
    { icon: faScrewdriverWrench, title: "Resolution Management" },
    { icon: faTimeline, title: "Transaction Monitoring" },
    { icon: faClock, title: "Performance Monitoring" },
    { icon: faHandshakeAngle, title: "Performance Coaching" },
    { icon: faPlus, title: "Add a Service" },
  ];

  return (
    <div className='homeMainBox'>
      <span className='homeMainTitle'>{section} View</span>
      <div className='appsWrapper'>
        {iconsAndLabels.map((item, index) => (
          <AppCard key={index} icon={item.icon} title={item.title} />
        ))}
      </div>
    </div>
  );
};

export default HomeFeed;
