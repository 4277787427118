import { Link } from "react-router-dom";
import TopBar from "../../components/TopBar/HomeTopBar/HomeTopBar";
import "./demoTopMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faPlus,
  faPrescription,
  faHandHoldingHeart,
  faSyringe,
} from "@fortawesome/free-solid-svg-icons";

const DemoTopMenu = ({ data }) => {
  const iconsMap = {
    faGlobe: faGlobe,
    faPlus: faPlus,
    faPrescription: faPrescription,
    faHandHoldingHeart: faHandHoldingHeart,
    faSyringe: faSyringe,
  };
  return (
    <div className='demoContainer'>
      <TopBar />
      <div className='topMenuContainer'>
        {data.map((item, index) => (
          <>
            <span className='topMenuTitle'>{item.title}</span>
            <div className='topMenuSubs'>
              {item.children?.map((sub, i) => (
                <Link to={`/demo/${sub.path}`} className='topMenuLink'>
                  <div className='topMenuSubCard'>
                    <FontAwesomeIcon
                      icon={iconsMap[`${sub.icon}`]}
                      className='topMenuIcon'
                    />
                    <span className='topMenuSubCardTitle'>{sub.title}</span>
                  </div>
                </Link>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default DemoTopMenu;
