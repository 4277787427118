import "./App.css";
import { useState, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import Login from "./pages/Login/Login";
import Demo from "./pages/Demo/Demo";
import DemoTopMenu from "./pages/DemoTopMenu/DemoTopMenu";

function App() {
  const topMenu = [
    {
      title: "Contact Centers",
      path: "./parent",
      icon: "faGlobe",
      id: "1",
      children: [
        {
          title: "CGS",
          path: "./cgs",
          icon: "faGlobe",
          children: [],
        },
        {
          title: "Add More",
          path: "./create",
          icon: "faPlus",
          children: [],
        },
      ],
    },
  ];
  const cgsMenu = [
    {
      title: "CGS",
      path: "./parent",
      icon: "faGlobe",
      id: "1",
      children: [
        {
          title: "Carelon RX",
          path: "./cgs/cgsrx",
          icon: "faPrescription",
          children: [],
        },
        {
          title: "CareMore",
          path: "./cgs/caremore",
          icon: "faHandHoldingHeart",
          children: [],
        },
        {
          title: "Clinical",
          path: "./cgs/clinical",
          icon: "faSyringe",
          children: [],
        },
      ],
    },
  ];
  return (
    <div className='appContainer'>
      <Routes>
        <Route path='/demo/*' element={<DemoTopMenu data={topMenu} />} />
        <Route path='/demo/cgs' element={<DemoTopMenu data={cgsMenu} />} />
        <Route path='/demo/cgs/cgsrx/*' element={<Demo />} />
        <Route path='/demo/cgs/caremore/*' element={<Demo />} />
        <Route path='/demo/cgs/clinical/*' element={<Demo />} />
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Landing />} />
      </Routes>
    </div>
  );
}

export default App;
