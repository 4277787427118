import React, { useState } from "react";
import "./landing.css";
import LandingTopBar from "../../components/TopBar/LandingTopBar/LandingTopBar";
import requestUrl from "../../utils/requestMethods.js";

const Landing = () => {
  const [contactForm, setContactForm] = useState({
    email: undefined,
    agentCount: undefined,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleChange = (e) => {
    let input = e.target.value;
    setContactForm((prev) => ({ ...prev, [e.target.id]: input }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit", contactForm);
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: "same-origin",
      };
      const res = await requestUrl.post("/forms/contact", contactForm, config);
      console.log(res.data);
      setIsFormSubmitted(true);
    } catch (error) {
      if (error.response?.data !== undefined) console.log(error.response?.data);
      else console.log(error);
    }
  };

  return (
    <>
      <LandingTopBar />
      <section id='landingHome'>
        <p className='headline'>
          Welcome to <span className='logo'>CareOS</span>
        </p>
        <p className='supportingCopy'>
          The Operating System for Customer Care Excellence
        </p>
      </section>
      <section id='landingAbout'>
        <p className='subHeadline'>
          Why Choose <span className='logo'>CareOS</span>?
        </p>
        <p className='supportingCopy about'>
          Our all-in-one superapp platform is engineered specifically to
          streamline your customer care operations from demand planning to staff
          performance optimization.
        </p>
      </section>
      <section id='landingContact'>
        {!isFormSubmitted ? (
          <>
            <p className='subHeadline contact'>
              Fill out the form to get started.
            </p>
            <article role='form'>
              <form
                className='landingForm'
                aria-label='Contact Form'
                onSubmit={handleSubmit}
              >
                <div className='formInput'>
                  <label htmlFor='email' className='landingLabel'>
                    Company Email
                  </label>
                  <input
                    id='email'
                    className='landingInput'
                    type='email'
                    placeholder='Name@company.com'
                    onChange={handleChange}
                  />
                </div>
                <div className='formInput'>
                  <label htmlFor='agentCount' className='landingLabel'>
                    Number of Agents
                  </label>
                  <input
                    id='agentCount'
                    className='landingInput'
                    type='number'
                    min='1'
                    placeholder='How many staff does your company have?'
                    onChange={handleChange}
                  />
                </div>
                <p className='getConsent'>
                  <span className='logo'>CareOS</span> needs your consent to
                  contact you so we can schedule your demo. You may unsubscribe
                  at any time.
                </p>
                <button type='submit' className='btn' aria-label='Submit Form'>
                  Submit
                </button>
              </form>
            </article>
          </>
        ) : (
          <>
            <p className='subHeadline contact'>
              Thank you for your interest. We will be in touch soon.
            </p>
          </>
        )}
      </section>
    </>
  );
};

export default Landing;
